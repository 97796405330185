import React from "react"
import Img from "gatsby-image"
import { LightgalleryItem } from "react-lightgallery"
import { Link } from "gatsby"
import image from "../../images/all/1.jpg"
import "./slide.css"

const Slide = ({ slide }) => {
  var slideImage =
    slide.childImageSharp && slide.childImageSharp.fluid
      ? slide.childImageSharp.fluid
      : slide.childMarkdownRemark.frontmatter.featuredImage.childImageSharp
          .fluid

  return (
    <div className="fet_pr-carousel-box">
      <div className="fet_pr-carousel-box-media fl-wrap">
        <Img fluid={slideImage} className="respimg" alt={slide.id} />
        <LightgalleryItem itemClassName="" group="any" src={image}>
          <Link className="fet_pr-carousel-box-media-zoom image-popup" to="#">
            <i className="fal fa-search"></i>
          </Link>
        </LightgalleryItem>
      </div>
      {slide.childMarkdownRemark &&
      slide.childMarkdownRemark.frontmatter &&
      slide.childMarkdownRemark.frontmatter.name ? (
        <div className="fet_pr-carousel-box-text fl-wrap">
          <h3>
            <Link
              to={
                slide.childMarkdownRemark.frontmatter.url ||
                slide.childMarkdownRemark.fields.slug
              }
            >
              {slide.childMarkdownRemark.frontmatter.name}
            </Link>
          </h3>
          <div className="fet_pr-carousel-cat">
            <Link to="/">{slide.childMarkdownRemark.frontmatter.category}</Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default Slide
